<template>
	<div class="pui-form">
		<mclase-modals :modelName="modelName"></mclase-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<pui-field-set class="ml-4 mr-4 mb-4" :title="getTitleClase">
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<v-row dense>
							<!-- CÓDIGO -->
							<v-col cols="1">
								<pui-text-field
									:id="`codigo-mclase`"
									v-model="model.codigo"
									:label="$t('mclase.codigo')"
									:disabled="formDisabled"
									toplabel
									required
									maxlength="7"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- DESCRIPCION -->
							<v-col cols="4">
								<pui-text-field
									:id="`descripcion-mclase`"
									v-model="model.descripcion"
									:label="$t('mclase.descripcion')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="250"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="3">
								<pui-date-field
									:id="`fecbaja-mclase`"
									v-model="model.fecbaja"
									:label="$t('mclase.fecbaja')"
									:disabled="formDisabled"
									toplabel
									time
								></pui-date-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
			</pui-field-set>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mclaseActions from './MclaseActions';
import mclaseModals from './MclaseModals.vue';

export default {
	name: 'mclase-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mclase-modals': mclaseModals
	},
	data() {
		return {
			modelName: 'mclase',
			actions: mclaseActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		getTitleClase() {
			return this.$t('form.mclase');
		}
	},
	created() {}
};
</script>
